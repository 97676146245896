import Axios from "axios";
import cookieCutter from 'cookie-cutter'

const http = Axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

const accessToken = cookieCutter.get('access_token')
if(accessToken){
  http.defaults.headers.common["Authorization"] = `Bearer ${accessToken}`;

  // handle cookie expired
  http.get('/auth/user')
    .then(response => {
      if(response.data.errors){
        cookieCutter.set("access_token", "", {path: "/"});
        cookieCutter.set("fullname", "", {path: "/"});
        cookieCutter.set("role_id", "", {path: "/"});
        cookieCutter.set("login_attempt", "", {path: "/"});
        window.location = '/auth/sign-in'
      }
    })
    .catch(error => {
      console.log(error)
    });
}

export default http;
