/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Utils
import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import Swal from "sweetalert2";
import http from "utils/http";
import EngageTable from "./engage/table";
import BlastTable from "./blast/table";
import SoftInput from "components/SoftInput";

function OrderHistory() {
  const route = useLocation().pathname.replace('/history', '').split("/").slice(1).join("/");
  const [searchKeyword, setSearchKeyword] = useState('')
  const [orders, setOrders] = useState([]);
  const [allOrders, setAllOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });

  const fetchAllOrders = async () => {
    const service_type = route.split("-");
    const response = await http.get(`/order/${service_type[0]}`);
    const result = await Promise.all(
      response.data.data.map(async (result) => {
        const fetchServiceType = await http.get(
          `/service/${result.service_id}`
        );
        const orderCode = result.order_code.split("-");
        
        return {
          time: new Date(result.created_at).toLocaleString("en-GB", {
            minute: "2-digit",
            hour: "2-digit",
            day: "2-digit",
            month: "2-digit",
            year: "numeric",
          }),
          order_id: result.id,
          order_code: `REPORT-${orderCode[1]}`,
          service_type: fetchServiceType.data.data.name,
          quantity: result.order_qty,
          target: JSON.stringify(result.data),
          status: result.order_status,
          report: result.order_report,
        };
      })
    );
    setAllOrders(result)
  }

  const fetchOrders = async (pageNumber) => {
    setIsLoading(true);
    try {
      const service_type = route.split("-");
      const response = await http.get(
        `/order/${service_type[0]}?page=${pageNumber}`
      );
      const totalPage = Math.ceil(response.data.data.total / 10);
      const results = response.data.data.results;

      const fetchedOrders = await Promise.all(
        results.map(async (result) => {
          const fetchServiceType = await http.get(
            `/service/${result.service_id}`
          );
          const orderCode = result.order_code.split("-");

          return {
            time: new Date(result.created_at).toLocaleString("en-GB", {
              minute: "2-digit",
              hour: "2-digit",
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
            }),
            order_id: result.id,
            order_code: `REPORT-${orderCode[1]}`,
            service_type: fetchServiceType.data.data.name,
            quantity: `${result.order_executed <= 0 ? result.success : result.order_executed}/${result.order_qty}`,
            target: JSON.stringify(result.data),
            status: result.order_status,
            report: result.order_report,
          };
        })
      );

      setOrders(fetchedOrders);
      setPage({
        onPage: pageNumber,
        totalData: response.data.data.total,
        totalPage: totalPage,
      });
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  const handleSearch = (keyword) => {
    const filtered = allOrders.filter((order) => 
      order.time.includes(keyword) ||
      order.service_type.toLowerCase().includes(keyword) ||
      order.order_code.toLowerCase().includes(keyword) ||
      order.service_type.includes(keyword) ||
      order.order_code.includes(keyword)
    )
    setFilteredOrders(filtered)
    document.querySelector('#footer-pagination-table').style.display = 'none'
  }

  useEffect(() => {
    fetchAllOrders()
    fetchOrders(1);
  }, [route]);

  useEffect(() => {
    if(searchKeyword){
      handleSearch(searchKeyword)
    }else{
      setFilteredOrders([])
      if(document.querySelector('#footer-pagination-table')){
        document.querySelector('#footer-pagination-table').style.display = 'block'
      }
    }
  }, [searchKeyword])

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      fetchOrders(page.onPage + 1);
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      fetchOrders(page.onPage - 1);
    }
  };

  const handleViewTarget = (data) => {
    const convertJSON = (data) => {
      let result
      try {
        result = JSON.parse(data); // Remove the inner JSON.parse call
      } catch (error) {
        return false
      }
      return result
    }

    let result = convertJSON(data)
    let resultHtml = "";

    if(result == false) result = data

    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        // Check if the property exists in 'result'
        if (Array.isArray(result[key])) {
          const mergedArray = result[key].join("\n");
          resultHtml += `
            <label for="${key}" class="form-label">${
            result[key] && result[key].includes("http") ? "link" : key
          }</label><br/>
            <textarea id="${key}" class="form-textarea" disabled>${mergedArray}</textarea><br/>
          `;
        } else {
          resultHtml += `
            <label for="${key}" class="form-label">${
            result[key] && result[key].includes("://") ? "link" : key
          }</label><br/>
            <input type="text" id="${key}" value="${
            result[key]
          }" class="form-input" disabled><br/>
          `;
        }
      }
    }

    Swal.fire({
      title: "Target Data",
      html: resultHtml,
      showCloseButton: true,
      showCancelButton: true,
      showConfirmButton: false,
      focusConfirm: false,
      cancelButtonText: "Close",
    });
  };

  if (orders.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={3} mb={8}>
          <Grid container>
            <BeatLoader color="#21D4FD" />
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftInput type="text" placeholder="search..." mb={3} onChange={(e) => setSearchKeyword(e.target.value)}/>
      <SoftBox mt={3} mb={8}>
        {route === "engage-history" && (
          <EngageTable
            orders={searchKeyword ? filteredOrders : orders}
            page={page}
            onLoading={isLoading}
            onViewTarget={handleViewTarget}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            isPage={filteredOrders.length <= 0}
            fetchOrders={fetchOrders}
          />
        )}

        {route === "blast-history" && (
          <BlastTable
            orders={searchKeyword ? filteredOrders : orders}
            page={page}
            onLoading={isLoading}
            onViewTarget={handleViewTarget}
            onNextPage={handleNextPage}
            onPreviousPage={handlePrevPage}
            isPage={filteredOrders.length <= 0}
            fetchOrders={fetchOrders}
          />
        )}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default OrderHistory;
