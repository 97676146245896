/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import TablePagination from "examples/Tables/TablePagination";

// Soft UI Dashboard PRO React example components
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";

function AccountsTable({
  account,
  page,
  onLoading,
  onPasswordDisplay,
  onPasswordVisibility,
  onNextPage,
  onPreviousPage,
}) {
  return (
    <>
      {!account ? (
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={8} lg={10}>
            <SoftBox mb={3}>
              <TablePagination
                columns={[{ name: "Data", align: "center" }]}
                rows={[{ Data: "Empty" }]}
                page={page}
                handleNextPage={onNextPage}
                handlePrevPage={onPreviousPage}
              />
            </SoftBox>
          </Grid>
        </Grid>
      ) : (
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={8} lg={10}>
            <SoftBox mb={3}>
              <TablePagination
                columns={[
                  { name: "username", align: "left" },
                  { name: "password", align: "left" },
                  { name: "email", align: "left" },
                  { name: "status", align: "left" },
                ]}
                rows={
                  onLoading
                    ? Array.from({ length: 10 }).map((_, index) => ({
                        username: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        password: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        email: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        status: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                      }))
                    : account.map((row, index) => ({
                        username: row.username,
                        password: (
                          <Grid
                            container
                            display="flex"
                            direction="row"
                            spacing={2}
                          >
                            <Grid item>
                              <SoftTypography fontSize={14}>
                                {onPasswordDisplay(row.password, row.isHidden)}
                              </SoftTypography>
                            </Grid>
                            <Grid item>
                              <SoftButton
                                variant="outlined"
                                color="info"
                                size="small"
                                hide="false"
                                onClick={() => onPasswordVisibility(index)}
                              >
                                {row.isHidden ? "Show" : "Hide"}
                              </SoftButton>
                            </Grid>
                          </Grid>
                        ),
                        email: row.email,
                        status: row.status,
                      }))
                }
                page={page}
                handleNextPage={onNextPage}
                handlePrevPage={onPreviousPage}
              />
            </SoftBox>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default AccountsTable;
