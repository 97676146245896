import React, { createContext, useEffect, useState } from 'react'
import http from 'utils/http'
import cookieCutter from 'cookie-cutter'
import routeMenus from '../routes'

export const RoleAccessContext = createContext()

export const RoleAccessProvider = ({children}) => {
    const [roleAccess, setRoleAccess] = useState([])

    const getRoleAccess = async () => {
        const response = await http.get(`/role-access/${cookieCutter.get('role_id')}`)
        setRoleAccess(response.data.data)
    }

    const setMenuAccess = (roleAccess) => {
        if(cookieCutter.get('role_id')){
            const accessMenuChild = roleAccess
            const accessMenuParent = [...new Set(accessMenuChild.map(access => access.parent_menu))]
            accessMenuParent.forEach((accessMenu) => {
                const parentMenuIdx = routeMenus.findIndex((routeMenuParent) => routeMenuParent.key === accessMenu)
                routeMenus[parentMenuIdx].hide = false
            
                if(routeMenus[parentMenuIdx].collapse){
                    routeMenus[parentMenuIdx].collapse.forEach((routeMenuChild) => {
                        if(accessMenuChild.find((accessMenu) => accessMenu.parent_menu === routeMenus[parentMenuIdx].key && accessMenu.child_menu === routeMenuChild.key)) routeMenuChild.hide = false
                    })
                }
            })
        }
    }

    useEffect(() => {
        if(cookieCutter.get('role_id')){
            getRoleAccess()
        }

        if(roleAccess.length > 0){
            setMenuAccess(roleAccess)
        }
    }, [roleAccess.length < 1 && roleAccess])

    return (
        <RoleAccessContext.Provider value={{roleAccess, routes: routeMenus}}>
            {children}
        </RoleAccessContext.Provider>
    )
}