/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// react-router-dom components
import { useNavigate } from "react-router-dom";

// @mui material components
import Switch from "@mui/material/Switch";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";

// Authentication layout components
import IllustrationLayout from "layouts/authentication/components/IllustrationLayout";

// Image
import chat from "assets/images/illustrations/chat.png";

// utils
import http from "utils/http";
import cookieCutter from 'cookie-cutter'
import Swal from "sweetalert2";
import { BeatLoader } from "react-spinners";

function Illustration() {
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countLoginAttempt, setCountLoginAttempt] = useState(1)

  const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const handleLogin = async (e) => {
    e.preventDefault()
    const formData = new FormData(e.target)
    const loginBlockedExpiry = cookieCutter.get('login_blocked');
    const blockedMinutes = 15

    setIsLoading(true)

    const loginBlockedTime = new Date(loginBlockedExpiry);
    const currentTime = new Date();

    if (loginBlockedExpiry && loginBlockedTime > currentTime) {
      const remainingMilliseconds = loginBlockedTime - currentTime;
      const remainingMinutes = Math.floor(remainingMilliseconds / (60 * 1000));

      await Swal.fire({
        icon: 'error',
        title: 'Login Blocked',
        text: `Please try again after ${remainingMinutes} minutes.`,
      });

      e.target.reset();
    }else{
      try {
        setCountLoginAttempt((currState) => currState + 1)
        if(cookieCutter.get("login_attempt")){
          await cookieCutter.set("login_attempt", parseInt(cookieCutter.get("login_attempt")) + 1, {path: "/"});
        }else{
          await cookieCutter.set("login_attempt", countLoginAttempt, {path: "/"});
        }

        const response = await http.post('/auth/login', {
          email: formData.get('email'),
          password: formData.get('password')
        })
        const date = new Date();
        const expDate = new Date(date.getTime() + (12 * 60 * 60 * 1000));
        const apiKey = response.data.data.apiKey;
        await cookieCutter.set("access_token", apiKey, {expires: expDate, path: "/"});
        await cookieCutter.set("fullname", response.data.data.fullname, {expires: expDate, path: "/"});
        await cookieCutter.set("role_id", response.data.data.role_id, {expires: expDate, path: "/"});
        await cookieCutter.set("login_blocked", "", {path: "/"});
        http.defaults.headers.common["Authorization"] = `Bearer ${apiKey}`;
        window.location.reload()
      } catch (error) {
        if(parseInt(cookieCutter.get("login_attempt")) >= 3){
          const blockedTime = new Date(Date.now() + blockedMinutes * 60 * 1000);
          await cookieCutter.set("login_blocked", blockedTime, {expires: blockedTime, path: "/"});
  
          Swal.fire({
            icon: 'error',
            title: 'login was expired',
            text: `please try again after ${blockedMinutes} minutes`,
          })

          e.target.reset()
          await cookieCutter.set("login_attempt", 1, {path: "/"});
        }else{
          Swal.fire({
            icon: 'error',
            title: 'login was fails',
            text: error.response.data.message,
          })
        }
  
      }
    }

    setIsLoading(false)
  }

  return (
    <IllustrationLayout
      title="Sign In"
      description="Enter your email and password to sign in"
      illustration={{
        image: chat,
        title: null,
        description: null,
      }}
    >
      <SoftBox component="form" role="form" onSubmit={(e) => handleLogin(e)}>
        <SoftBox mb={2}>
          <SoftInput type="email" placeholder="Email" size="large" name="email" />
        </SoftBox>
        <SoftBox mb={2}>
          <SoftInput type="password" placeholder="Password" size="large" name="password" />
        </SoftBox>
        <SoftBox display="flex" alignItems="center">
          <Switch checked={rememberMe} onChange={handleSetRememberMe} />
          <SoftTypography
            variant="button"
            fontWeight="regular"
            onClick={handleSetRememberMe}
            sx={{ cursor: "pointer", userSelect: "none" }}
          >
            &nbsp;&nbsp;Remember me
          </SoftTypography>
        </SoftBox>
        <SoftBox mt={4} mb={1}>
          <SoftButton variant="gradient" color="info" size="large" type="submit" fullWidth disabled={isLoading}>
            {isLoading ? <BeatLoader color="#21D4FD" /> : 'sign in'}
          </SoftButton>
        </SoftBox>
        {/* <SoftBox mt={3} textAlign="center">
          <SoftTypography variant="button" color="text" fontWeight="regular">
            Don&apos;t have an account?{" "}
            <SoftTypography
              component={Link}
              to="/authentication/sign-up/illustration"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign up
            </SoftTypography>
          </SoftTypography>
        </SoftBox> */}
      </SoftBox>
    </IllustrationLayout>
  );
}

export default Illustration;
