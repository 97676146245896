/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftInput from "components/SoftInput";
import SoftSelect from "components/SoftSelect";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";
import Modal from "@mui/material/Modal";

// Soft UI Dashboard PRO React example components
import TablePagination from "examples/Tables/TablePagination";
import Swal from "sweetalert2";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import http from "utils/http";
import {
  ROLE_ADMIN,
  ROLE_KOPRAL,
  ROLE_SERSAN,
  ROLE_MAYOR,
  ROLE_PRO_BUSINESS,
  ROLE_BASIC,
} from "utils/const";

const roleMap = {
  1: "Admin",
  2: "Kopral",
  3: "Sersan",
  4: "Mayor",
  5: "Pro Business",
  6: "Basic",
};

const roleOptions = [
  { value: ROLE_ADMIN, label: "Admin" },
  { value: ROLE_KOPRAL, label: "Kopral" },
  { value: ROLE_SERSAN, label: "Sersan" },
  { value: ROLE_MAYOR, label: "Mayor" },
  { value: ROLE_PRO_BUSINESS, label: "Pro Business" },
  { value: ROLE_BASIC, label: "Basic" },
];

const EditModal = ({
  modalEditOpen,
  setModalEditOpen,
  editUser,
  detailUser,
  isLoading,
}) => {
  return (
    <>
      <Modal
        open={modalEditOpen}
        onClose={() => setModalEditOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            boxShadow: 24,
            p: 4,
            minWidth: 500,
            borderRadius: "10px",
          }}
        >
          <SoftTypography id="modal-modal-title" variant="h5" component="h2">
            Edit User
          </SoftTypography>
          <SoftBox sx={{ mt: 2 }}>
            <form onSubmit={(e) => editUser(e)}>
              <input type="hidden" name="id" defaultValue={detailUser.id} />
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Username <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="Username"
                  name="username"
                  defaultValue={detailUser.fullname}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Email <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="Email"
                  name="email"
                  defaultValue={detailUser.email}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Password <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="password"
                  placeholder="ex: Rhino12"
                  name="password"
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Role <span style={{color: 'red'}}>*</span></label>
                <SoftSelect
                  name="role_id"
                  defaultValue={roleOptions.filter(
                    (role) => role.value === detailUser.role_id
                  )}
                  options={roleOptions}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftButton
                  color="info"
                  size="small"
                  type="submit"
                  sx={{ mr: 1 }}
                  disabled={isLoading}
                >
                  {isLoading ? <BeatLoader color="#21D4FD" /> : "Submit"}
                </SoftButton>
                <SoftButton
                  color="secondary"
                  size="small"
                  onClick={() => setModalEditOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
};

const CreateModal = ({
  modalCreateOpen,
  setModalCreateOpen,
  createUser,
  isLoading,
}) => {
  return (
    <>
      <Modal
        open={modalCreateOpen}
        onClose={() => setModalCreateOpen(false)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <SoftBox
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            boxShadow: 24,
            p: 4,
            minWidth: 500,
            borderRadius: "10px",
          }}
        >
          <SoftTypography id="modal-modal-title" variant="h5" component="h2">
            Create User
          </SoftTypography>
          <SoftBox sx={{ mt: 2 }}>
            <form onSubmit={(e) => createUser(e)}>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Username <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: johndoe"
                  name="username"
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Email <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="text"
                  placeholder="ex: johndoe@mail.com"
                  name="email"
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Password <span style={{color: 'red'}}>*</span></label>
                <SoftInput
                  type="password"
                  placeholder="ex: Rhino12"
                  name="password"
                  required
                />
              </SoftBox>
              <SoftBox mb={3}>
                <label style={{fontSize: 16}} >Role <span style={{color: 'red'}}>*</span></label>
                <SoftSelect name="role_id" options={roleOptions} required />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftButton
                  color="info"
                  size="small"
                  type="submit"
                  sx={{ mr: 1 }}
                  disabled={isLoading}
                >
                  {isLoading ? <BeatLoader color="#21D4FD" /> : "Submit"}
                </SoftButton>
                <SoftButton
                  color="secondary"
                  size="small"
                  onClick={() => setModalCreateOpen(false)}
                  disabled={isLoading}
                >
                  Cancel
                </SoftButton>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </Modal>
    </>
  );
};

export default function UserManagement() {
  const route = useLocation().pathname.split("/").slice(1).join("/");
  const navigate = useNavigate();
  const [modalEditOpen, setModalEditOpen] = useState(false);
  const [modalCreateOpen, setModalCreateOpen] = useState(false);
  const [user, setUser] = useState([]);
  const [detailUser, setDetailUser] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const currentUser = await http.get("/auth/verify");
        const role = currentUser.data.data.role.name;

        if (role !== "Admin") {
          return Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "You don't have any access to this resources!",
          }).then((result) => {
            if (result.isConfirmed) {
              navigate("/dashboard");
            }
          });
        }
      } catch (e) {
        console.log(e);
      }
    };

    fetchCurrentUser();
  }, []);

  const showEditModal = async (userID) => {
    try {
      const response = await http.get(`/user/${userID}`);
      setDetailUser(response.data.data);
      setModalEditOpen(true);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const createUser = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      setIsLoading(true);
      await http.post("/user", {
        fullname: formData.get("username"),
        email: formData.get("email"),
        password: formData.get("password"),
        role_id: formData.get("role_id"),
      });
      setIsLoading(false);
      await Swal.fire({
        icon: "success",
        title: "User Created!",
      });
      setModalCreateOpen(false);
      fetchUsers();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const editUser = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);

    try {
      setIsLoading(true);
      await http.put(`/user/update-user/${formData.get("id")}`, {
        fullname: formData.get("username"),
        email: formData.get("email"),
        password: formData.get("password"),
        role_id: formData.get("role_id"),
      });
      setIsLoading(true);
      await Swal.fire({
        icon: "success",
        title: "User Updated!",
      });
      setModalEditOpen(false);
      fetchUsers();
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Something went wrong",
      });
    }
  };

  const deleteUser = async (userID) => {
    const confirm = await Swal.fire({
      icon: "info",
      title: "You sure?",
      showCancelButton: true,
    });
    if (confirm.isConfirmed) {
      try {
        await http.delete(`/user/${userID}`);
        await Swal.fire({
          icon: "success",
          title: "User deleted!",
        });
        fetchUsers();
      } catch (error) {
        Swal.fire({
          icon: "error",
          title: "Something went wrong",
        });
      }
    }
  };

  const fetchUsers = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/user?page=${page.onPage}`);
      const totalPage = Math.ceil(response.data.data.total / 10);

      setUser(response.data.data.results);
      setPage((curr) => ({
        onPage: totalPage < curr.onPage ? 1 : curr.onPage,
        totalData: response.data.data.total,
        totalPage: totalPage,
      }));
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [page.onPage]);

  useEffect(() => {
    setPage((curr) => ({
      ...curr,
      onPage: 1,
    }));
    fetchUsers();
  }, [route[1]]);

  if (user.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={3} mb={8}>
          <Grid container>
            <BeatLoader color="#21D4FD" />
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <EditModal
        modalEditOpen={modalEditOpen}
        setModalEditOpen={setModalEditOpen}
        editUser={editUser}
        detailUser={detailUser}
        isLoading={isLoading}
      />
      <CreateModal
        modalCreateOpen={modalCreateOpen}
        setModalCreateOpen={setModalCreateOpen}
        createUser={createUser}
        isLoading={isLoading}
      />
      <SoftBox mb={2}>
        <TablePagination
          columns={[
            { name: "Created At", align: "left" },
            { name: "Name", align: "left" },
            { name: "Email", align: "left" },
            { name: "Role", align: "left" },
            { name: "Action", align: "left" },
          ]}
          rows={
            isLoading
              ? Array.from({ length: 10 }).map((_, index) => ({
                  "Created At": (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Name: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Email: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Role: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Action: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                }))
              : user.map((row, index) => ({
                  "Created At": new Date(row.created_at).toLocaleString(
                    "en-GB",
                    {
                      minute: "2-digit",
                      hour: "2-digit",
                      day: "2-digit",
                      month: "2-digit",
                      year: "numeric",
                    }
                  ),
                  Name: row.fullname,
                  Email: row.email,
                  Role: (
                    <SoftButton variant="outlined" color="info" size="small">
                      {roleMap[row.role_id]}
                    </SoftButton>
                  ),
                  Action: (
                    <Grid
                      container
                      rowSpacing={1}
                      columnSpacing={{ xs: 1, sm: 2, md: 2 }}
                    >
                      <Grid item xs={6}>
                        <SoftButton
                          variant="gradient"
                          color="info"
                          size="small"
                          onClick={() => showEditModal(row.id)}
                        >
                          Edit
                        </SoftButton>
                      </Grid>
                      <Grid item xs={6}>
                        <SoftButton
                          color="error"
                          size="small"
                          onClick={() => deleteUser(row.id)}
                        >
                          Delete
                        </SoftButton>
                      </Grid>
                    </Grid>
                  ),
                }))
          }
          page={page}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleCreate={() => setModalCreateOpen(true)}
        />
      </SoftBox>
    </DashboardLayout>
  );
}
