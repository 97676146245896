import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import http from "utils/http";
import { FaPlusCircle, FaTrash, FaMinusCircle } from "react-icons/fa";
import Papa from "papaparse";
import { FaFile } from "react-icons/fa6";
import SoftSelect from "components/SoftSelect";
import { useLocation } from "react-router-dom";

export default function TwitterTweet() {
  const route = useLocation().pathname.split("/")[2]
  const [groups, setGroups] = useState([]);
  const [total, setTotal] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [maxTotal, setMaxTotal] = useState(1);
  const inputCSV = useRef();

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const captions = formData.getAll("caption");

    // Perform form validation
    if (!total || captions.filter((caption) => caption == "").length > 0) {
      setError("All fields are required");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      let mediaFilename = null;

      if (selectedFile) {
        // Upload the file to the first API endpoint
        const uploadFormData = new FormData();
        uploadFormData.append("file", selectedFile);

        const uploadResponse = await http.post(
          "/upload/minio",
          uploadFormData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        // Check if the file upload was successful
        if (uploadResponse.status === 200) {
          mediaFilename = uploadResponse.data.data.filename;
        } else {
          throw new Error("File upload failed");
        }
      }
      const data = JSON.stringify({ caption: captions, media: mediaFilename });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "64",
        data: data,
      });

      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Blast successfully created",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }

    setIsLoading(false);
    // reset form
    setTotal(1);
    setSelectedFile(null);
    e.target.reset();
  };

  const handleGenerateCSV = async () => {
    const results = await generateCSV(inputCSV.current.files[0]);
    const captions = Array.from(document.querySelectorAll('[name="caption"]'));
    captions.forEach(
      (caption, idx) => (caption.textContent = results[idx].captions)
    );
  };

  const generateCSV = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setTotal(results.data.length);
            resolve(results.data);
          },
          error: function (err) {
            reject(err);
          },
        });
      });
    }
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("twitter");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if (activeCount.length > 0) {
      setMaxTotal(activeCount[0].amount);
    } else {
      setMaxTotal(0);
    }

  };

  const getGroups = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/${route}/group`);
      setGroups(response.data.data)
      console.log(response)
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchAccountsTotal();
    getGroups()
  }, []);

  useEffect(() => {
    if (inputCSV.current.files[0]) {
      handleGenerateCSV();
    }
  }, [inputCSV.current ? inputCSV.current.files[0] : null]);

  return (
    <>
      <Grid container>
        <Grid item xs={12} lg={7}>
          <SoftBox
            color="white"
            borderRadius="lg"
            shadow="lg"
            opacity={1}
            p={2}
            md={6}
          >
            <SoftBox p={2}>
              <SoftBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={3}
              >
                <SoftTypography variant="h5" fontWeight="bold">
                  Form Tweet
                </SoftTypography>

                <input
                  type="file"
                  accept=".csv"
                  ref={inputCSV}
                  style={{ display: "none" }}
                  onChange={(e) => generateCSV(e.target.files[0])}
                />
                <SoftButton
                  type="button"
                  variant="contained"
                  color="success"
                  onClick={() => inputCSV.current.click()}
                >
                  <FaFile /> <span style={{ marginLeft: "5px" }}>csv</span>
                </SoftButton>
              </SoftBox>
              <form onSubmit={(e) => handleSubmit(e)}>
                <SoftBox mb={3}>
                  <SoftInput
                    disabled
                    placeholder="Total"
                    value={total}
                    onChange={(e) => setTotal(e.target.value)}
                  />
                </SoftBox>
                <SoftBox mb={3}>
                  <SoftSelect
                    placeholder="groups.."
                    name="group"
                    options={groups.map((group) => ({ value: group, label: group }))}
                    size="medium"
                  />
                </SoftBox>
                <SoftBox mb={3} h={2}>
                  <div>
                    <input
                      type="file"
                      id="fileInput"
                      onChange={handleFileChange}
                    />
                  </div>
                  {error && (
                    <SoftTypography variant="body2" color="error" mb={3}>
                      {error}
                    </SoftTypography>
                  )}
                </SoftBox>

                {[...Array(total)].map((e, i) => {
                  return (
                    <SoftBox mb={3}>
                      <SoftInput
                        placeholder="Caption"
                        multiline
                        rows={5}
                        name="caption"
                      // onChange={(e) => setCaption(e.target.value)}
                      />
                    </SoftBox>
                  );
                })}
                <SoftBox display="flex" justifyContent="space-between">
                  <SoftButton
                    disabled={isLoading}
                    type="submit"
                    variant="contained"
                    color="info"
                  >
                    {isLoading ? <ClipLoader size={20} /> : "Blast"}
                  </SoftButton>
                  <SoftBox>
                    <SoftButton
                      type="button"
                      variant="contained"
                      color="info"
                      onClick={() => {
                        if (total < maxTotal) {
                          setTotal((curr) => curr + 1);
                        }
                      }}
                      style={{ marginRight: "5px" }}
                    >
                      <FaPlusCircle />{" "}
                      <span style={{ marginLeft: "5px" }}>Caption</span>
                    </SoftButton>
                    <SoftButton
                      type="button"
                      variant="contained"
                      color="error"
                      onClick={() => {
                        if (total > 1) setTotal((curr) => curr - 1);
                      }}
                    >
                      <FaMinusCircle />{" "}
                      <span style={{ marginLeft: "5px" }}>Caption</span>
                    </SoftButton>
                  </SoftBox>
                </SoftBox>
              </form>
            </SoftBox>
          </SoftBox>
        </Grid>
      </Grid>
    </>
  );
}
