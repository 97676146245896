/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import { useLocation } from "react-router-dom";

import facebookLike from "./facebook/like";
import facebookFollow from "./facebook/follow";
import facebookComment from "./facebook/comment";
import facebookView from "./facebook/view";
import facebookGpt from "./facebook/ai";

import igLike from "./instagram/like";
import igFollow from "./instagram/follow";
import igComment from "./instagram/comment";
import igView from "./instagram/view";
import igGpt from "./instagram/ai";

import ytLike from "./youtube/like";
import ytSubscribe from "./youtube/follow";
import ytComment from "./youtube/comment";
import ytView from "./youtube/view";
import ytGpt from "./youtube/ai";

import tiktokLike from "./tiktok/like";
import tiktokFollow from "./tiktok/follow";
import tiktokComment from "./tiktok/comment";
import tiktokView from "./tiktok/view";
import tiktokGpt from "./tiktok/ai";

import twitterLike from "./twitter/like";
import twitterFollow from "./twitter/follow";
import twitterReply from "./twitter/reply";
import twitterRetweet from "./twitter/retweet";
import twitterGpt from "./twitter/ai";

function Engage() {
  const path = useLocation().pathname;
  const route = useLocation().pathname.split("/").slice(1);
  const componentMap = {
    "/engage/facebook/like": facebookLike,
    "/engage/facebook/follow": facebookFollow,
    "/engage/facebook/comment": facebookComment,
    "/engage/facebook/view": facebookView,
    "/engage/facebook/ai": facebookGpt,
    "/engage/instagram/like": igLike,
    "/engage/instagram/follow": igFollow,
    "/engage/instagram/comment": igComment,
    "/engage/instagram/view": igView,
    "/engage/instagram/ai": igGpt,
    "/engage/twitter/like": twitterLike,
    "/engage/twitter/follow": twitterFollow,
    "/engage/twitter/reply": twitterReply,
    "/engage/twitter/retweet": twitterRetweet,
    "/engage/twitter/ai": twitterGpt,
    "/engage/youtube/like": ytLike,
    "/engage/youtube/comment": ytComment,
    "/engage/youtube/subscribe": ytSubscribe,
    "/engage/youtube/view": ytView,
    "/engage/youtube/ai": ytGpt,
    "/engage/tiktok/like": tiktokLike,
    "/engage/tiktok/comment": tiktokComment,
    "/engage/tiktok/follow": tiktokFollow,
    "/engage/tiktok/view": tiktokView,
    "/engage/tiktok/ai": tiktokGpt,
  };

  const Component = componentMap[path];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={3} mb={8}>
        {Component && <Component />}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Engage;
