/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useLocation } from "react-router-dom";

// Facebook components
import FacebookDm from "./facebook/dm";
import FacebookPost from "./facebook/post";
import FacebookShare from "./facebook/share";
import FacebookDmGpt from "./facebook/ai-dm";
import FacebookPostGpt from "./facebook/ai";

// Instagram components
import InstagramDm from "./instagram/dm";
import InstagramPost from "./instagram/post";
import InstagramLive from "./instagram/live";
import InstagramDmGpt from "./instagram/ai-dm";
import InstagramPostGpt from "./instagram/ai";

// Twitter components
import TwitterTweet from "./twitter/tweet";
import TwitterShare from "./twitter/share";
import TwitterQuote from "./twitter/quote";
import TwitterGpt from "./twitter/ai";
import QuoteGpt from "./twitter/ai-quote";

// Utils
import http from "utils/http";

function Blast() {
  const path = useLocation().pathname;
  const componentMap = {
    "/blast/facebook/dm": FacebookDm,
    "/blast/facebook/post": FacebookPost,
    "/blast/facebook/share": FacebookShare,
    "/blast/facebook/ai-dm": FacebookDmGpt,
    "/blast/facebook/ai": FacebookPostGpt,
    "/blast/instagram/dm": InstagramDm,
    "/blast/instagram/post": InstagramPost,
    "/blast/instagram/live": InstagramLive,
    "/blast/instagram/ai-dm": InstagramDmGpt,
    "/blast/instagram/ai": InstagramPostGpt,
    "/blast/twitter/tweet": TwitterTweet,
    "/blast/twitter/share": TwitterShare,
    "/blast/twitter/quote-tweet": TwitterQuote,
    "/blast/twitter/tweet-ai": TwitterGpt,
    "/blast/twitter/quote-ai": QuoteGpt,
  };
  const Component = componentMap[path];

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mt={3} mb={8}>
        {Component && <Component />}
      </SoftBox>
      {/* <Footer /> */}
    </DashboardLayout>
  );
}

export default Blast;
