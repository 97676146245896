import { useRef, useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import http from "utils/http";
import { ClipLoader } from "react-spinners";
import { FaMinusCircle, FaPlusCircle } from "react-icons/fa";
import Papa from "papaparse";
import { FaFile } from "react-icons/fa6";
import { useLocation } from "react-router-dom";
import SoftSelect from "components/SoftSelect";

export default function Reply() {
  const route = useLocation().pathname.split("/")[2]
  const [groups, setGroups] = useState([]);
  const [total, setTotal] = useState(1);
  const [caption, setCaption] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [maxTotal, setMaxTotal] = useState(1);
  const inputCSV = useRef();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const comments = formData.getAll("comment");
    if (
      !total ||
      formData.getAll("comment").filter((comment) => comment == "").length >
      0 ||
      !link
    ) {
      setError("All fields are required");
      return;
    }

    if (total < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Total can't less than 1!!",
      });
      return;
    } else if (total > maxTotal) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Cannot exceed ${maxTotal}!`,
      });
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      const formData = new FormData(e.target)
      const data = JSON.stringify({ caption: comments, link, group: formData.get('group') });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "105",
        data: data,
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your blast has been created successfully",
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }
    setIsLoading(false);
  };

  function countOrderByDynamicFormInput(value) {
    let text = value.split("\n");
    text = text.map((val) => val.trim());
    text = text.filter((val) => val !== "");
    return text.length;
  }

  const handleGenerateCSV = async () => {
    const results = await generateCSV(inputCSV.current.files[0]);
    const captions = Array.from(document.querySelectorAll('[name="comment"]'));
    captions.forEach(
      (caption, idx) => (caption.textContent = results[idx].captions)
    );
  };

  const generateCSV = (file) => {
    if (file) {
      return new Promise((resolve, reject) => {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setTotal(results.data.length);
            resolve(results.data);
          },
          error: function (err) {
            reject(err);
          },
        });
      });
    }
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("twitter");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if (activeCount.length > 0) {
      setMaxTotal(activeCount[0].amount);
    } else {
      setMaxTotal(0);
    }

  };

  const getGroups = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/${route}/group`);
      setGroups(response.data.data)
      console.log(response)
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchAccountsTotal();
    getGroups()
  }, []);

  useEffect(() => {
    if (inputCSV.current.files[0]) {
      handleGenerateCSV();
    }
  }, [inputCSV.current ? inputCSV.current.files[0] : null]);

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        <SoftBox
          color="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          md={6}
        >
          <SoftBox p={2}>
            <SoftBox
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mb={3}
            >
              <SoftTypography variant="h5" fontWeight="bold">
                Form Reply
              </SoftTypography>

              <input
                type="file"
                accept=".csv"
                ref={inputCSV}
                style={{ display: "none" }}
                onChange={(e) => generateCSV(e.target.files[0])}
              />
              <SoftButton
                type="button"
                variant="contained"
                color="success"
                onClick={() => inputCSV.current.click()}
              >
                <FaFile /> <span style={{ marginLeft: "5px" }}>csv</span>
              </SoftButton>
            </SoftBox>
            <form onSubmit={handleSubmit}>
              <SoftBox mb={3}>
                {[...Array(total)].map((e, i) => {
                  return (
                    <SoftBox mb={3}>
                      <SoftInput
                        placeholder="Caption"
                        multiline
                        rows={5}
                        name="comment"
                      />
                    </SoftBox>
                  );
                })}
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput
                  placeholder="Link eg. https://twitter.com/john.doe/status/1675422339213795329"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftSelect
                  placeholder="groups.."
                  name="group"
                  options={groups.map((group) => ({ value: group, label: group }))}
                  size="medium"
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput placeholder="Total" value={total} disabled />
                <SoftTypography style={{ fontSize: 12 }} color="error" mt={1}>
                  * Total will increase it numbers based on new lines on caption
                  section
                </SoftTypography>
                {error && (
                  <SoftTypography style={{ fontSize: 12 }} color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftBox display="flex" justifyContent="space-between">
                <SoftButton
                  disabled={isLoading}
                  type="submit"
                  variant="contained"
                  color="info"
                >
                  {isLoading ? <ClipLoader size={20} /> : "Blast"}
                </SoftButton>
                <SoftBox>
                  <SoftButton
                    type="button"
                    variant="contained"
                    color="info"
                    onClick={() => {
                      if (total < maxTotal) {
                        setTotal((curr) => curr + 1);
                      }
                    }}
                    style={{ marginRight: "5px" }}
                  >
                    <FaPlusCircle />{" "}
                    <span style={{ marginLeft: "5px" }}>Caption</span>
                  </SoftButton>
                  <SoftButton
                    type="button"
                    variant="contained"
                    color="error"
                    onClick={() => {
                      if (total > 1) setTotal((curr) => curr - 1);
                    }}
                  >
                    <FaMinusCircle />{" "}
                    <span style={{ marginLeft: "5px" }}>Caption</span>
                  </SoftButton>
                </SoftBox>
              </SoftBox>
            </form>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
