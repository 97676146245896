import React, { useEffect, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import { Chart } from 'chart.js';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet';
import markerIcon from 'leaflet/dist/images/marker-icon.png';
import markerIconRetina from 'leaflet/dist/images/marker-icon-2x.png';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIconRetina,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

const locationData = [
  { location: 'Aceh', latitude: 4.6951, longitude: 96.7494 },
  { location: 'Bali', latitude: -8.4095, longitude: 115.1889 },
  { location: 'Bangka Belitung Islands', latitude: -2.7411, longitude: 106.4406 },
  { location: 'Banten', latitude: -6.4058, longitude: 106.0649 },
  { location: 'Bengkulu', latitude: -3.8004, longitude: 102.2564 },
  { location: 'Central Java', latitude: -7.1509, longitude: 110.1409 },
  { location: 'Central Kalimantan', latitude: -1.6815, longitude: 113.3824 },
  { location: 'Central Sulawesi', latitude: -1.4300, longitude: 121.4456 },
  { location: 'East Java', latitude: -7.5361, longitude: 112.2384 },
  { location: 'East Kalimantan', latitude: 1.6102, longitude: 116.0056 },
  { location: 'East Nusa Tenggara', latitude: -8.6574, longitude: 121.0794 },
  { location: 'Gorontalo', latitude: 0.6999, longitude: 122.4467 },
  { location: 'Jakarta', latitude: -6.2088, longitude: 106.8456 },
  { location: 'Jambi', latitude: -1.6106, longitude: 103.6131 },
  { location: 'Lampung', latitude: -5.4294, longitude: 105.2621 },
  { location: 'Maluku', latitude: -3.2385, longitude: 130.1453 },
  { location: 'North Kalimantan', latitude: 2.8022, longitude: 116.8388 },
  { location: 'North Maluku', latitude: 1.5756,longitude: 127.8087 },
  { location: 'North Sulawesi', latitude: 1.3541, longitude: 124.9922 },
  { location: 'North Sumatra', latitude: 3.5952, longitude: 98.6722 },
  { location: 'Papua', latitude: -4.2699, longitude: 138.0804 },
  { location: 'Riau', latitude: 0.5121, longitude: 101.4461 },
  { location: 'Riau Islands', latitude: 3.9457, longitude: 108.1429 },
  { location: 'South Kalimantan', latitude: -3.0926, longitude: 115.2838 },
  { location: 'South Sulawesi', latitude: -3.6688, longitude: 119.9741 },
  { location: 'South Sumatra', latitude: -3.3194, longitude: 103.9144 },
  { location: 'Southeast Sulawesi', latitude: -4.1449, longitude: 122.1746 },
  { location: 'West Java', latitude: -6.9147, longitude: 107.6098 },
  { location: 'West Kalimantan', latitude: -0.2788, longitude: 111.4753 },
  { location: 'West Nusa Tenggara', latitude: -8.6500, longitude: 117.3650 },
  { location: 'West Papua', latitude: -1.3361, longitude: 133.1747 },
  { location: 'West Sulawesi', latitude: -2.8374, longitude: 119.2922 },
  { location: 'West Sumatra', latitude: -0.7893, longitude: 100.3657 },
  { location: 'Yogyakarta', latitude: -7.7971, longitude: 110.3686 }
];

const TroopsMapping = ({stats}) => {
	const facebookShare = stats.facebook / locationData.length;
	const twitterShare = stats.twitter / locationData.length;
	const instagramShare = stats.instagram / locationData.length;
	const youtubeShare = stats.youtube / locationData.length;

	locationData.forEach((province) => {
	  province.facebook = Math.round(facebookShare);
	  province.twitter = Math.round(twitterShare);
	  province.instagram = Math.round(instagramShare);
	  province.youtube = Math.round(youtubeShare);
	});

  const mapRef = useRef(null);
  const mapBounds = [[-11, 94], [6, 141]];
  const chartsRef = useRef({});
    const customIcon = L.icon({
      iconUrl: markerIcon,
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [1, -34],
      shadowUrl: markerShadow,
      shadowSize: [41, 41],
    });

  useEffect(() => {
    if (mapRef.current) {
      const mapContainer = mapRef.current._container;
      mapContainer.style.background = '#000000';

      locationData.forEach((data) => {
        const marker = mapRef.current._layers[data.markerId];
        if (marker && marker.setIcon) {
          marker.setIcon(customIcon);
        }
      });
    }
  }, [mapRef]);

  const createChart = (data) => {
    const chartCanvas = document.getElementById(`chart-${data.location}`);
    if (chartCanvas) {
      const ctx = chartCanvas.getContext('2d');
      const existingChart = chartsRef.current[data.location];
      if (existingChart) {
        existingChart.destroy();
      }
      const newChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['Twitter', 'Instagram', 'Facebook', 'Youtube'],
          datasets: [
            {
              label: data.location,
              data: [data.twitter, data.instagram, data.facebook, data.youtube],
              backgroundColor: ['rgba(255, 99, 132, 0.2)', 'rgba(54, 162, 235, 0.2)', 'rgba(255, 206, 86, 0.2)'],
              borderColor: ['rgba(255, 99, 132, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)'],
              borderWidth: 1,
            },
          ],
        },
      });
      chartsRef.current[data.location] = newChart;
    }
  };

  return (
    <div style={{ height: '300px', width: '100%' }}>
      <MapContainer
        center={[-2.5489, 118.0149]}
        zoom={4}
        style={{ height: '100%', width: '100%' }}
        ref={mapRef}
        className="map-container"
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {locationData.map((data, index) => (
          <Marker
            key={index}
            position={[data.latitude, data.longitude]}
            eventHandlers={{
              click: () => {
                setTimeout(() => {
                  createChart(data);
                }, 50);
              },
            }}
            icon={customIcon}
          >
            <Popup>
              <div>
                <canvas id={`chart-${data.location}`} width="200" height="200" className="canvas-chart" />
              </div>
            </Popup>
          </Marker>
        ))}
      </MapContainer>
    </div>
  );
};

export default TroopsMapping;