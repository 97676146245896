// Soft UI Dashboard PRO React layouts
import Default from "layouts/dashboards/default";
import SignInIllustration from "layouts/authentication/sign-in/illustration";
import Engage from "layouts/pages/engage";
import Blast from "layouts/pages/blast";
import OrderHistory from "layouts/pages/order";
import MonitoringAccount from "layouts/pages/monitoring-account";
import DigitalVillage from "layouts/pages/digital-village";
import UserManagement from "layouts/pages/admin/user-management";
import RoleManagement from "layouts/pages/admin/role-management";
import SocialMediaAccount from "layouts/pages/admin/social-media-account";

// Soft UI Dashboard PRO React icons
import Document from "examples/Icons/Document";
import { FaPeopleGroup, FaChalkboardUser } from "react-icons/fa6";
import { BiWorld } from "react-icons/bi";
import { MdOutlineMonitor } from "react-icons/md";

const routes = [
  // define route component
  {
    type: "collapse",
    menu: false,
    name: "Dashboards",
    key: "dashboards",
    hide: true,
    collapse: [
      {
        name: "Dashboard",
        key: "dashboard",
        route: "/dashboard",
        component: <Default />,
      },
    ],
  },
  {
    type: "collapse",
    menu: false,
    name: "User Managements",
    key: "user-managements",
    hide: true,
    collapse: [
      {
        name: "User Management",
        key: "user-management",
        route: "/user-management",
        component: <UserManagement />,
      },
    ],
  },
  {
    type: "collapse",
    menu: false,
    name: "Role Managements",
    key: "role-managements",
    hide: true,
    collapse: [
      {
        name: "Role Management",
        key: "role-management",
        route: "/role-management",
        component: <RoleManagement />,
      },
    ],
  },
  {
    type: "collapse",
    menu: false,
    name: "Digital Villages",
    key: "digital-villages",
    hide: true,
    collapse: [
      {
        name: "Digital Villages",
        key: "digital-village",
        route: "/digital-village",
        component: <DigitalVillage />,
      },
    ],
  },
  {
    type: "collapse",
    menu: false,
    name: "Auth",
    key: "auth",
    icon: <Document size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Sign In",
        key: "sign-in",
        collapse: [
          {
            name: "Illustration",
            key: "illustration",
            route: "/auth/sign-in",
            component: <SignInIllustration />,
          },
        ],
      },
    ],
  },
];

let routeMenus = [
  // sidebar menu
  {
    type: "collapse",
    menu: true,
    key: "dashboard",
    href: "/dashboard",
    name: "Dashboard",
    icon: <BiWorld size="12px" />,
    hide: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    menu: true,
    name: "Social Media Account",
    key: "social-media-account",
    icon: <MdOutlineMonitor size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Facebook",
        key: "facebook",
        hide: true,
        route: "/social-media-account/facebook",
        component: <SocialMediaAccount />,
      },
      {
        name: "Instagram",
        key: "instagram",
        hide: true,
        route: "/social-media-account/instagram",
        component: <SocialMediaAccount />,
      },
      {
        name: "Twitter",
        key: "twitter",
        hide: true,
        route: "/social-media-account/twitter",
        component: <SocialMediaAccount />,
      },
      {
        name: "Youtube",
        key: "youtube",
        hide: true,
        route: "/social-media-account/youtube",
        component: <SocialMediaAccount />,
      },
      {
        name: "TikTok",
        key: "tiktok",
        hide: true,
        route: "/social-media-account/tiktok",
        component: <SocialMediaAccount />,
      },
    ],
  },
  {
    type: "collapse",
    menu: true,
    name: "Engage",
    key: "engage",
    icon: <FaPeopleGroup size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Instagram",
        key: "instagram",
        hide: true,
        collapse: [
          {
            name: "Like",
            key: "like",
            route: "/engage/instagram/like",
            component: <Engage />,
          },
          {
            name: "Follow",
            key: "follow",
            route: "/engage/instagram/follow",
            component: <Engage />,
          },
          {
            name: "Comment",
            key: "comment",
            route: "/engage/instagram/comment",
            component: <Engage />,
          },
          {
            name: "View",
            key: "view",
            route: "/engage/instagram/view",
            component: <Engage />,
          },
          {
            name: "Comment AI",
            key: "commentAI",
            route: "/engage/instagram/ai",
            component: <Engage />,
          },
        ],
      },
      {
        name: "Twitter",
        key: "twitter",
        hide: true,
        collapse: [
          {
            name: "Like",
            key: "like",
            route: "/engage/twitter/like",
            component: <Engage />,
          },
          {
            name: "Follow",
            key: "follow",
            route: "/engage/twitter/follow",
            component: <Engage />,
          },
          {
            name: "Reply",
            key: "reply",
            route: "/engage/twitter/reply",
            component: <Engage />,
          },
          {
            name: "Retweet",
            key: "retweet",
            route: "/engage/twitter/retweet",
            component: <Engage />,
          },
          {
            name: "Reply AI",
            key: "commentAI",
            route: "/engage/twitter/ai",
            component: <Engage />,
          },
        ],
      },
      {
        name: "Facebook",
        key: "facebook",
        hide: true,
        collapse: [
          {
            name: "Like",
            key: "like",
            route: "/engage/facebook/like",
            component: <Engage />,
          },
          {
            name: "Follow",
            key: "follow",
            route: "/engage/facebook/follow",
            component: <Engage />,
          },
          {
            name: "Comment",
            key: "comment",
            route: "/engage/facebook/comment",
            component: <Engage />,
          },
          {
            name: "View",
            key: "view",
            route: "/engage/facebook/view",
            component: <Engage />,
          },
          {
            name: "Comment AI",
            key: "commentAI",
            route: "/engage/facebook/ai",
            component: <Engage />,
          },
        ],
      },
      {
        name: "Tiktok",
        key: "tiktok",
        hide: true,
        collapse: [
          {
            name: "Like",
            key: "like",
            route: "/engage/tiktok/like",
            component: <Engage />,
          },
          {
            name: "Follow",
            key: "follow",
            route: "/engage/tiktok/follow",
            component: <Engage />,
          },
          {
            name: "Comment",
            key: "comment",
            route: "/engage/tiktok/comment",
            component: <Engage />,
          },
          {
            name: "View",
            key: "view",
            route: "/engage/tiktok/view",
            component: <Engage />,
          },
          {
            name: "Comment AI",
            key: "commentAI",
            route: "/engage/tiktok/ai",
            component: <Engage />,
          },
        ],
      },

      {
        name: "Youtube",
        key: "youtube",
        hide: true,
        collapse: [
          {
            name: "Like",
            key: "like",
            route: "/engage/youtube/like",
            component: <Engage />,
          },
          {
            name: "Subscribe",
            key: "subscribe",
            route: "/engage/youtube/subscribe",
            component: <Engage />,
          },
          {
            name: "Comment",
            key: "comment",
            route: "/engage/youtube/comment",
            component: <Engage />,
          },
          {
            name: "View",
            key: "view",
            route: "/engage/youtube/view",
            component: <Engage />,
          },
          {
            name: "Comment AI",
            key: "commentAI",
            route: "/engage/youtube/ai",
            component: <Engage />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    menu: true,
    name: "Blast",
    key: "blast",
    icon: <FaPeopleGroup size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Instagram",
        key: "instagram",
        hide: true,
        collapse: [
          {
            name: "Post",
            key: "post",
            route: "/blast/instagram/post",
            component: <Blast />,
          },
          {
            name: "Live",
            key: "live",
            route: "/blast/instagram/live",
            component: <Blast />,
          },
          {
            name: "DM",
            key: "dm",
            route: "/blast/instagram/dm",
            component: <Blast />,
          },
          {
            name: "DM AI",
            key: "gpt-dm",
            route: "/blast/instagram/ai-dm",
            component: <Blast />,
          },
          {
            name: "Post AI",
            key: "gpt-post",
            route: "/blast/instagram/ai",
            component: <Blast />,
          },
        ],
      },
      {
        name: "Twitter",
        key: "twitter",
        hide: true,
        collapse: [
          {
            name: "Tweet",
            key: "tweet",
            route: "/blast/twitter/tweet",
            component: <Blast />,
          },
          {
            name: "Quote Tweet",
            key: "quote-tweet",
            route: "/blast/twitter/quote-tweet",
            component: <Blast />,
          },
          {
            name: "Tweet AI",
            key: "gpt",
            route: "/blast/twitter/tweet-ai",
            component: <Blast />,
          },
          {
            name: "Quote AI",
            key: "quote-ai",
            route: "/blast/twitter/quote-ai",
            component: <Blast />,
          },
        ],
      },
      {
        name: "Facebook",
        key: "facebook",
        hide: true,
        collapse: [
          {
            name: "Post",
            key: "post",
            route: "/blast/facebook/post",
            component: <Blast />,
          },
          {
            name: "Share",
            key: "share",
            route: "/blast/facebook/share",
            component: <Blast />,
          },
          {
            name: "DM",
            key: "dm",
            route: "/blast/facebook/dm",
            component: <Blast />,
          },
          {
            name: "DM AI",
            key: "gpt-dm",
            route: "/blast/facebook/ai-dm",
            component: <Blast />,
          },
          {
            name: "Post AI",
            key: "gpt-post",
            route: "/blast/facebook/ai",
            component: <Blast />,
          },
        ],
      },
    ],
  },
  {
    type: "collapse",
    menu: true,
    name: "History",
    key: "history",
    icon: <MdOutlineMonitor size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Engage History",
        key: "engage-history",
        hide: true,
        route: "/history/engage-history",
        component: <OrderHistory />,
      },
      {
        name: "Blast History",
        key: "blast-history",
        hide: true,
        route: "/history/blast-history",
        component: <OrderHistory />,
      },
    ],
  },
  {
    type: "collapse",
    menu: true,
    name: "Monitoring Account",
    key: "monitoring-account",
    icon: <MdOutlineMonitor size="12px" />,
    hide: true,
    collapse: [
      {
        name: "Facebook",
        key: "facebook",
        hide: true,
        route: "/monitoring-account/facebook",
        component: <MonitoringAccount />,
      },
      {
        name: "Instagram",
        key: "instagram",
        hide: true,
        route: "/monitoring-account/instagram",
        component: <MonitoringAccount />,
      },
      {
        name: "Twitter",
        key: "twitter",
        hide: true,
        route: "/monitoring-account/twitter",
        component: <MonitoringAccount />,
      },
      {
        name: "Youtube",
        key: "youtube",
        hide: true,
        route: "/monitoring-account/youtube",
        component: <MonitoringAccount />,
      },
      {
        name: "TikTok",
        key: "tiktok",
        hide: true,
        route: "/monitoring-account/tiktok",
        component: <MonitoringAccount />,
      },
    ],
  },
  {
    type: "collapse",
    menu: true,
    name: "User Management",
    key: "user-management",
    href: "/user-management",
    icon: <FaChalkboardUser size="12px" />,
    hide: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    menu: true,
    name: "Digital Village",
    key: "digital-village",
    href: "/digital-village",
    icon: <FaChalkboardUser size="12px" />,
    hide: true,
    noCollapse: true,
  },
  {
    type: "collapse",
    menu: true,
    name: "Role Management",
    key: "role-management",
    href: "/role-management",
    icon: <FaChalkboardUser size="12px" />,
    hide: true,
    noCollapse: true,
  },
];

routeMenus.push(...routes);

export default routeMenus;
