import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import { ClipLoader } from "react-spinners";
import http from "utils/http";
import SoftSelect from "components/SoftSelect";
import { useLocation } from "react-router-dom";

export default function InstagramLive() {
  const route = useLocation().pathname.split("/")[2]
  const [groups, setGroups] = useState([]);
  const [total, setTotal] = useState("");
  const [username, setUsername] = useState("");
  const [watch, setWatch] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [maxTotal, setMaxTotal] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform form validation
    if (!total || !username || !watch) {
      setError("All fields are required");
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      const data = JSON.stringify({ username, watch });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "127",
        data: data,
      });

      console.log(response.data);
      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Blast successfully created",
      });
    } catch (error) {
      console.log(error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }

    setIsLoading(false);

    // reset form
    setTotal("");
    setUsername("");
    setWatch("");
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("instagram");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if (activeCount.length > 0) {
      setMaxTotal(activeCount[0].amount);
    } else {
      setMaxTotal(0);
    }

  };

  const getGroups = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/${route}/group`);
      setGroups(response.data.data)
      console.log(response)
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchAccountsTotal();
    getGroups()
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        <SoftBox
          color="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          md={6}
        >
          <SoftBox p={2}>
            <SoftTypography variant="h5" fontWeight="bold" mb={3}>
              Form Live
            </SoftTypography>
            <form onSubmit={(e) => handleSubmit(e)}>
              <SoftBox mb={3}>
                <SoftInput
                  type="number"
                  placeholder="Total"
                  onChange={(e) => setTotal(e.target.value)}
                  onBlur={(e) => {
                    const totalInput = parseInt(e.target.value)
                    const maxGenerate = 200

                    if (maxTotal > maxGenerate) {
                      if (totalInput >= maxGenerate || isNaN(totalInput)) {
                        e.target.value = maxGenerate
                        setTotal(maxGenerate)
                      }
                    } else if (maxTotal < maxGenerate || isNaN(totalInput)) {
                      if (totalInput >= maxTotal) {
                        e.target.value = maxTotal
                        setTotal(maxTotal)
                      }
                    }
                  }}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftSelect
                  placeholder="groups.."
                  name="group"
                  options={groups.map((group) => ({ value: group, label: group }))}
                  size="medium"
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput
                  type="number"
                  placeholder="Time watch by minute"
                  value={watch}
                  onChange={(e) => setWatch(e.target.value)}
                />
              </SoftBox>
              <SoftBox mb={3} h={2}>
                <SoftInput
                  type="text"
                  placeholder="Username eg. zuck"
                  value={username}
                  onChange={(e) => setUsername(e.target.value)}
                />
                {error && (
                  <SoftTypography variant="body2" color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="info"
              >
                {isLoading ? <ClipLoader size={20} /> : "Blast"}
              </SoftButton>
            </form>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
