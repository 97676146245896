/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import SoftAvatar from "components/SoftAvatar";
import TablePagination from "examples/Tables/TablePagination";

// Soft UI Dashboard PRO React example components
import { useEffect, useState } from "react";
import profile from "../../../assets/images/icons/profile.png";

function TroopsTable({
  accountInfo,
  page,
  onLoading,
  onNextPage,
  onPreviousPage,
}) {
  return (
    <>
      {!accountInfo ? (
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={8} lg={10}>
            <SoftBox mb={3}>
              <TablePagination
                columns={[{ name: "Data", align: "center" }]}
                rows={[
                  {
                    Data: "Empty",
                  },
                ]}
                page={page}
                handleNextPage={onNextPage}
                handlePrevPage={onPreviousPage}
              />
            </SoftBox>
          </Grid>
        </Grid>
      ) : (
        <Grid container display="flex" justifyContent="center">
          <Grid item xs={12} md={8} lg={10}>
            <SoftBox mb={3}>
              <TablePagination
                columns={[
                  { name: "Profile Picture", align: "center" },
                  { name: "Name", align: "left" },
                  { name: "Username", align: "left" },
                  { name: "Age", align: "left" },
                  { name: "Bio", align: "left" },
                  { name: "Dob", align: "left" },
                  { name: "Hobby", align: "left" },
                  { name: "Following", align: "left" },
                  { name: "Followers", align: "left" },
                  { name: "Last IP", align: "left" },
                ]}
                rows={
                  onLoading
                    ? Array.from({ length: 10 }).map((_, index) => ({
                        "Profile Picture": (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          ></div>
                        ),
                        Name: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        Username: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        Age: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "50px", height: "20px" }}
                          ></div>
                        ),
                        Bio: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "200px", height: "20px" }}
                          ></div>
                        ),
                        Dob: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        Hobby: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                        Following: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "50px", height: "20px" }}
                          ></div>
                        ),
                        Followers: (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "50px", height: "20px" }}
                          ></div>
                        ),
                        "Last IP": (
                          <div
                            key={index}
                            className="loading-skeleton"
                            style={{ width: "100px", height: "20px" }}
                          ></div>
                        ),
                      }))
                    : accountInfo.map((row, index) => ({
                        "Profile Picture": (
                          <Grid container display="flex" direction="row">
                            <Grid item>
                              <SoftAvatar
                                src={
                                  row.info &&
                                  row.info.hasOwnProperty("profile_picture")
                                    ? `data:image/png;base64,${row.info.profile_picture}`
                                    : profile
                                }
                                alt="Profile Picture"
                              />
                            </Grid>
                          </Grid>
                        ),
                        Name:
                          row.info && row.info.hasOwnProperty("name")
                            ? row.info.name
                            : "-",
                        Username:
                          row.info && row.info.hasOwnProperty("username")
                            ? row.info.username
                            : "-",
                        Age:
                          row.info && row.info.hasOwnProperty("age")
                            ? row.info.age
                            : "-",
                        Bio:
                          row.info && row.info.hasOwnProperty("bio")
                            ? row.info.bio
                            : "-",
                        Dob:
                          row.info && row.info.hasOwnProperty("dob")
                            ? row.info.dob
                            : "-",
                        Hobby:
                          row.info && row.info.hasOwnProperty("hobby")
                            ? row.info.hobby
                            : "-",
                        Following:
                          row.info && row.info.hasOwnProperty("following")
                            ? row.info.following
                            : "-",
                        Followers:
                          row.info && row.info.hasOwnProperty("followers")
                            ? row.info.followers
                            : "-",
                        "Last IP":
                          row.info && row.info.hasOwnProperty("last_ip")
                            ? row.info.last_ip
                            : "-",
                      }))
                }
                page={page}
                handleNextPage={onNextPage}
                handlePrevPage={onPreviousPage}
              />
            </SoftBox>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default TroopsTable;
