/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";

// Soft UI Dashboard PRO React example components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import TablePagination from "examples/Tables/TablePagination";
import MiniStatisticsCard from "examples/Cards/StatisticsCards/MiniStatisticsCard";

// Utils
import { useLocation } from "react-router-dom";
import { FaCheck, FaQuestion, FaExclamation } from "react-icons/fa6";
import { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import http from "utils/http";
import TroopsTable from "./troops";
import AccountsTable from "./accounts";

function MonitoringAccount() {
  const route = useLocation().pathname.split("/").slice(1);
  const [accounts, setAccounts] = useState([]);
  const [accountInfo, setAccountInfo] = useState([]);
  const [accountStats, setAccountStats] = useState([]);
  const [platform, setPlatform] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [troopsIsLoading, setTroopsIsLoading] = useState(false);
  const [accountsIsLoading, setAccountsIsLoading] = useState(false);

  const [troopsPage, setTroopsPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 1,
  });

  const [paginationPage, setPaginationPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 1,
  });

  const fetchAccountData = async (pageNumber, tableType) => {
    if (tableType === "troops") {
      setTroopsIsLoading(true);
    } else if (tableType === "accounts") {
      setAccountsIsLoading(true);
    }

    try {
      const response = await http.get(`/${route[1]}?page=${pageNumber}&status=Active`);
      const totalData = response.data.data.total;
      const totalPage = Math.ceil(totalData / 10);

      // Process account data
      const processedAccounts = response.data.data.results.map((result) => ({
        username: result.username || "(Empty)",
        password: result.password,
        email: result.email || "(Empty)",
        status: result.status,
        isHidden: true,
      }));

      // Process account info data
      const processedAccountInfo = response.data.data.results.map((result) => ({
        info: result.info,
      }));

      if (tableType === "troops") {
        setAccountInfo(processedAccountInfo);
        setTroopsPage({
          onPage: pageNumber,
          totalData: totalData,
          totalPage: totalPage,
        });
      } else if (tableType === "accounts") {
        setAccounts(processedAccounts);
        setPaginationPage({
          onPage: pageNumber,
          totalData: totalData,
          totalPage: totalPage,
        });
      }
    } catch (error) {
      setAccountInfo(false);
      console.error(error);

      // Handle errors and set loading states to false here
      if (tableType === "troops") {
        setTroopsIsLoading(false);
      } else if (tableType === "accounts") {
        setAccountsIsLoading(false);
      }
    }

    // await new Promise((resolve) => setTimeout(resolve, 2000));

    if (tableType === "troops") {
      setTroopsIsLoading(false);
    } else if (tableType === "accounts") {
      setAccountsIsLoading(false);
    }
  };

  const handleNextPage = (tableType) => {
    const currentPage =
      tableType === "troops" ? troopsPage.onPage : paginationPage.onPage;
    const totalPage =
      tableType === "troops" ? troopsPage.totalPage : paginationPage.totalPage;

    if (currentPage < totalPage) {
      const nextPage = currentPage + 1;
      fetchAccountData(nextPage, tableType);
    }
  };

  const handlePrevPage = (tableType) => {
    const currentPage =
      tableType === "troops" ? troopsPage.onPage : paginationPage.onPage;

    if (currentPage !== 1) {
      const prevPage = currentPage - 1;
      fetchAccountData(prevPage, tableType);
    }
  };

  useEffect(() => {
    // Fetch account data when the component mounts
    fetchAccountData(1);
  }, [route[1]]);

  useEffect(() => {
    // Fetch account data when the component mounts
    fetchAccountData(1, "troops"); // Initialize TroopsTable pagination data
    fetchAccountData(1, "accounts"); // Initialize TablePagination pagination data
  }, [route[1]]);

  const getStatistics = async () => {
    setIsLoading(true);
    const getFirstChar = route[1][0].toUpperCase();
    const newRoute = route[1].replace(route[1][0], getFirstChar);

    setPlatform(newRoute);
    try {
      const response = await http.get(`statistics/`);
      setAccountStats(response.data.data);
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const togglePasswordVisibility = (index) => {
    setAccounts((prevAccounts) => {
      const updatedAccounts = [...prevAccounts];
      updatedAccounts[index].isHidden = !updatedAccounts[index].isHidden;
      return updatedAccounts;
    });
  };

  const getPasswordDisplay = (password, isHidden) => {
    if (isHidden) {
      // If hidden, show bullets based on password length
      return "•".repeat(password.length);
    } else {
      // If not hidden, show the actual password
      return password;
    }
  };

  useEffect(() => {
    // Fetch account data when the component mounts
    fetchAccountData(1);
  }, [route[1]]);

  useEffect(() => {
    getStatistics();
  }, [route[1]]);

  if (accounts.length === 0 || accountInfo.length === 0) {
    return (
      <DashboardLayout>
        <DashboardNavbar />
        <SoftBox mt={3} mb={8}>
          <Grid container>
            <BeatLoader color="#21D4FD" />
          </Grid>
        </SoftBox>
      </DashboardLayout>
    );
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox py={3}>
        <Grid container display="flex" justifyContent="center" spacing={2}>
          <Grid item xs={12} sm={2} md={3}>
            <SoftBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Active Account", fontWeight: "bold" }}
                count={accountStats[`active${platform}`]}
                icon={{ color: "info", component: <FaCheck /> }}
              />
            </SoftBox>
          </Grid>
          <Grid item xs={12} sm={2} md={3}>
            <SoftBox mb={3}>
              <MiniStatisticsCard
                title={{ text: "Suspended Account", fontWeight: "bold" }}
                count={accountStats[`verif${platform}`]}
                icon={{ color: "info", component: <FaExclamation /> }}
              />
            </SoftBox>
          </Grid>
        </Grid>
        <TroopsTable
          accountInfo={accountInfo}
          page={troopsPage}
          onLoading={troopsIsLoading}
          onNextPage={() => handleNextPage("troops")}
          onPreviousPage={() => handlePrevPage("troops")}
        />
        <AccountsTable
          account={accounts}
          page={paginationPage}
          onLoading={accountsIsLoading}
          onPasswordDisplay={getPasswordDisplay}
          onPasswordVisibility={togglePasswordVisibility}
          onNextPage={() => handleNextPage("accounts")}
          onPreviousPage={() => handlePrevPage("accounts")}
        />
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default MonitoringAccount;
