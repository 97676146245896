import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftTypography from "components/SoftTypography";
import SoftButton from "components/SoftButton";
import Swal from "sweetalert2";
import http from "utils/http";
import { ClipLoader } from "react-spinners";
import { useLocation } from "react-router-dom";
import SoftSelect from "components/SoftSelect";

export default function View() {
  const route = useLocation().pathname.split("/")[2]
  const [groups, setGroups] = useState([]);
  const [total, setTotal] = useState("");
  const [link, setLink] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [maxTotal, setMaxTotal] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!total || !link) {
      setError("All fields are required");
      return;
    }

    if (total < 1) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Total can't less than 1!!",
      });
      return;
    } else if (total > maxTotal) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: `Cannot exceed ${maxTotal}!`,
      });
      return;
    }

    setError("");
    setIsLoading(true);

    try {
      const formData = new FormData(e.target)
      const data = JSON.stringify({ link, group: formData.get('group') });
      const response = await http.post("order", {
        order_qty: total,
        service_id: "12",
        data: data,
      });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your blast has been created successfully",
      });

      console.log(response.data);
    } catch (error) {
      console.error("Error submitting form", error);
      Swal.fire({
        icon: "error",
        title: "Error",
        text: error.response.data.message,
      });
    }

    setIsLoading(false);
  };

  const fetchAccountsTotal = async () => {
    const accounts = await http.get("youtube");
    const activeCount = accounts.data.data.status_amount.filter(
      (account) => account.status == "Active"
    );
    if (activeCount.length > 0) {
      setMaxTotal(activeCount[0].amount);
    } else {
      setMaxTotal(0);
    }

  };

  const getGroups = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/${route}/group`);
      setGroups(response.data.data)
      console.log(response)
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  }

  useEffect(() => {
    fetchAccountsTotal();
    getGroups()
  }, []);

  return (
    <Grid container>
      <Grid item xs={12} lg={7}>
        <SoftBox
          color="white"
          borderRadius="lg"
          shadow="lg"
          opacity={1}
          p={2}
          md={6}
        >
          <SoftBox p={2}>
            <SoftTypography variant="h5" fontWeight="bold" mb={3}>
              Form View
            </SoftTypography>
            <form onSubmit={handleSubmit}>
              <SoftBox mb={3}>
                <SoftInput
                  placeholder="Link eg. https://www.youtube.com/watch?v=tPi5x15Eo&ab_channel=john.doe"
                  value={link}
                  onChange={(e) => setLink(e.target.value)}
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftSelect
                  placeholder="groups.."
                  name="group"
                  options={groups.map((group) => ({ value: group, label: group }))}
                  size="medium"
                />
              </SoftBox>
              <SoftBox mb={3}>
                <SoftInput
                  placeholder="Total"
                  onChange={(e) => setTotal(e.target.value)}
                  onBlur={(e) => {
                    const totalInput = parseInt(e.target.value)
                    const maxGenerate = 200

                    if (maxTotal > maxGenerate) {
                      if (totalInput >= maxGenerate || isNaN(totalInput)) {
                        e.target.value = maxGenerate
                        setTotal(maxGenerate)
                      }
                    } else if (maxTotal < maxGenerate || isNaN(totalInput)) {
                      if (totalInput >= maxTotal) {
                        e.target.value = maxTotal
                        setTotal(maxTotal)
                      }
                    }
                  }}
                />
                {error && (
                  <SoftTypography style={{ fontSize: 12 }} color="error" mb={3}>
                    {error}
                  </SoftTypography>
                )}
              </SoftBox>
              <SoftButton
                disabled={isLoading}
                type="submit"
                variant="contained"
                color="info"
              >
                {isLoading ? <ClipLoader size={20} /> : "Blast"}
              </SoftButton>
            </form>
          </SoftBox>
        </SoftBox>
      </Grid>
    </Grid>
  );
}
