/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

// Soft UI Dasboard PRO Material components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Soft UI Dasboard PRO Material base styles
import borders from "assets/theme/base/borders";
import colors from "assets/theme/base/colors";

function RoleAccess({ menu_key, color, noDivider, noCollapse, collapse, name, handleCheckAccessParent, handleCheckAccess }) {
  const { borderWidth } = borders;

  return (
    <SoftBox
      component="li"
      width="100%"
      pr={2}
      mb={2}
      borderLeft={`${borderWidth[3]} solid ${colors[color].main}`}
      sx={{ listStyle: "none" }}
    >
      <SoftBox width="100%" pl={1} ml={2}>
        <SoftBox display="flex" alignItems="center">
          <Checkbox id={`${menu_key}_parent`} onChange={(e) => handleCheckAccessParent(e)} />
          <SoftBox ml={0.2} lineHeight={1}>
            <SoftTypography variant="button" fontWeight="medium" htmlFor={`${menu_key}-parent`}>
              {name}
            </SoftTypography>
          </SoftBox>
        </SoftBox>
        {!noCollapse && 
          <>
            {collapse.map((childMenu, idx) => (
              <div key={idx}>
                <Checkbox className={`${menu_key}-child`} id={`${menu_key}_${childMenu.key}`} onChange={(e) => handleCheckAccess(e)} />
                <SoftTypography variant="button" fontWeight="medium" htmlFor={`${menu_key}_${childMenu.key}`} >
                  {childMenu.name}
                </SoftTypography>
              </div>
            ))}
          </>
        }
      </SoftBox>
      {noDivider ? null : <Divider sx={{ marginBottom: 0 }} />}
    </SoftBox>
  );
}

// Setting default values for the props of Todo
RoleAccess.defaultProps = {
  color: "info",
  noDivider: false,
  defaultChecked: false,
};

// Typechecking props for the Todo
RoleAccess.propTypes = {
  color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  project: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  defaultChecked: PropTypes.bool,
  noDivider: PropTypes.bool,
};

export default RoleAccess;
