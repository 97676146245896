/**
=========================================================
* Soft UI Dashboard PRO React - v4.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftInput from "components/SoftInput";
import SoftButton from "components/SoftButton";
import SoftTypography from "components/SoftTypography";

// Soft UI Dashboard PRO React example components
import TablePagination from "examples/Tables/TablePagination";
import Swal from "sweetalert2";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import http from "utils/http";
import { BeatLoader } from "react-spinners";

const badgeStatusMap = {
  1: "Success",
  2: "Retry",
  3: "Process",
  4: "Ended",
  5: "Queue",
};

const badgeStatusColor = {
  1: "success",
  2: "error",
  3: "warning",
  4: "error",
  5: "warning",
};

export default function EngageTable({
  orders,
  page,
  onLoading,
  onViewTarget,
  onNextPage,
  onPreviousPage,
  fetchOrders
}) {
  const [loadingIndex, setLoadingIndex] = useState(null);

  const handleDownloadClick = async (report, order_id, index) => {
    setLoadingIndex(index);

    try {
      await http.post(`/order/download/drive/${report}`, {
        order_id: order_id,
      });

      const reportUrl = `https://docs.google.com/spreadsheets/d/${report}`;
      window.open(reportUrl, "_blank");
    } catch (error) {
      console.error("Error downloading report:", error);
    } finally {
      setLoadingIndex(null);
    }
  };

  const handleRetryOrder = async (order_code, index) => {
    setLoadingIndex(index);
    try {
      await http.post(`/order/retry`, { order_code });

      Swal.fire({
        icon: "success",
        title: "Success",
        text: "Your blast has been created successfully",
      });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "Server busy,blast was not created",
      });
    }
    setLoadingIndex(null);
  }

  return (
    <SoftBox mb={3}>
      <TablePagination
        columns={[
          { name: "Time", align: "left" },
          { name: "Report Code", align: "left" },
          { name: "Service Type", align: "left" },
          { name: "Quantity", align: "left" },
          { name: "Target Data", align: "left" },
          { name: "Status", align: "left" },
          { name: "Report", align: "left" },
        ]}
        rows={
          onLoading
            ? Array.from({ length: 10 }).map((_, index) => ({
                Time: (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                "Report Code": (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                "Service Type": (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                Quantity: (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                "Target Data": (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                Status: (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
                Report: (
                  <div
                    key={index}
                    className="loading-skeleton"
                    style={{ width: "100px", height: "20px" }}
                  ></div>
                ),
              }))
            : orders.map((order, index) => ({
                Time: order.time,
                "Report Code": order.order_code,
                "Service Type": order.service_type,
                Quantity: order.quantity,
                "Target Data": (
                  <SoftButton
                    variant="outlined"
                    color="secondary"
                    size="small"
                    onClick={() => onViewTarget(order.target)}
                  >
                    View Data
                  </SoftButton>
                ),
                Status: (
                  <SoftButton
                    variant="outlined"
                    color={badgeStatusColor[order.status]}
                    size="small"
                    onClick={async () => {
                      // * 2 = retry
                      if(order.status == 2){
                        await handleRetryOrder(order.order_code.replace('REPORT', 'ORDER'), index)
                        await fetchOrders(page.onPage)
                      }
                    }}
                  >
                    {badgeStatusMap[order.status]}
                  </SoftButton>
                ),
                Report: (
                  order.report ? <SoftButton
                    color="info"
                    size="small"
                    onClick={() =>
                      handleDownloadClick(order.report, order.order_id, index)
                    }
                    disabled={loadingIndex !== null}
                  >
                    {loadingIndex !== index ? "View Report" : "Loading..."}
                  </SoftButton> : <SoftButton
                    color="info"
                    size="small"
                    disabled
                  >No Report</SoftButton>

                ),
              }))
        }
        page={page}
        handleNextPage={onNextPage}
        handlePrevPage={onPreviousPage}
      />
    </SoftBox>
  );
}
