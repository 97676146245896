import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Soft UI Dashboard PRO React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

const TroopsCard = ({props}) => {
	return (
		<Card>
			<SoftBox padding="10px">
				<SoftBox display="flex" justifyContent="space-between" alignItems="center" >
					<SoftTypography fontSize="14px" fontWeight="normal"><b>{props.username}</b></SoftTypography>
					<img src={props.photo} alt="photo" width="50" height="50" style={{borderRadius:'50%'}}/>
				</SoftBox>
				<SoftTypography fontSize="14px" fontWeight="normal">
					<b>Age:</b> {props.age}
				</SoftTypography>
				<SoftTypography fontSize="14px" fontWeight="normal">
					<b>location:</b> {props.location}
				</SoftTypography>
				<SoftTypography fontSize="14px" fontWeight="normal">
					<b>ip:</b> {props.last_ip}
				</SoftTypography>
			</SoftBox>
		</Card>
	)
}

export default TroopsCard;