import React, { useEffect, useState } from "react";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import SoftBox from "components/SoftBox";
import TablePagination from "examples/Tables/TablePagination";
import http from "utils/http";

const DigitalVillage = () => {
  const [digitalVillages, setDigitalVillages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState({
    onPage: 1,
    totalData: 0,
    totalPage: 0,
  });

  const getDigitalVillages = async () => {
    setIsLoading(true);

    try {
      const response = await http.get(`/activity-report?page=${page.onPage}`);
      const totalPage = Math.ceil(response.data.data.total / 10);

      setDigitalVillages(response.data.data.results);
      setPage((curr) => ({
        onPage: totalPage < curr.onPage ? 1 : curr.onPage,
        totalData: response.data.data.total,
        totalPage: totalPage,
      }));
    } catch (error) {
      console.log(error);
    }

    setIsLoading(false);
  };

  const handleNextPage = () => {
    if (page.onPage < page.totalPage) {
      setPage((curr) => ({
        onPage: curr.onPage + 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  const handlePrevPage = () => {
    if (page.onPage !== 1) {
      setPage((curr) => ({
        onPage: curr.onPage - 1,
        totalData: curr.totalData,
        totalPage: curr.totalPage,
      }));
    }
  };

  useEffect(() => {
    getDigitalVillages();
  }, [page.onPage]);

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <SoftBox mb={2}>
        <TablePagination
          columns={[
            { name: "Date", align: "left" },
            { name: "Platform", align: "left" },
            { name: "Action", align: "left" },
            { name: "Account", align: "left" },
          ]}
          rows={
            isLoading
              ? Array.from({ length: 10 }).map((_, index) => ({
                  Date: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Platform: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Action: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                  Account: (
                    <div
                      key={index}
                      className="loading-skeleton"
                      style={{ width: "100px", height: "20px" }}
                    ></div>
                  ),
                }))
              : digitalVillages.map((digitalVillage) => ({
                  Date: new Date(digitalVillage.date).toLocaleString("en-GB", {
                    minute: "2-digit",
                    hour: "2-digit",
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric",
                  }),
                  Platform: digitalVillage.platform,
                  Action: digitalVillage.action,
                  Account: digitalVillage.account.username,
                }))
          }
          page={page}
          handleNextPage={() => handleNextPage()}
          handlePrevPage={() => handlePrevPage()}
        />
      </SoftBox>
    </DashboardLayout>
  );
};

export default DigitalVillage;
