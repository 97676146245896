import React, { useEffect, useState } from 'react'
import DashboardLayout from 'examples/LayoutContainers/DashboardLayout'
import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import SoftBox from 'components/SoftBox'
import TablePagination from "examples/Tables/TablePagination";
import http from 'utils/http';
import SoftButton from 'components/SoftButton';
import Modal from '@mui/material/Modal';
import SoftTypography from 'components/SoftTypography';
import routes from '../../../../routes'
import Swal from "sweetalert2";
import { BeatLoader } from 'react-spinners';
import cookieCutter from 'cookie-cutter'
import RoleAccessComponent from './components/RoleAccess'


const ModalManageV2 = ({modalManageOpen, setModalManageOpen, menus, roleAccess, role_id, role_name, loading}) => {
    let checkedAccess = []

    const setAccessRole = async (e) => {
        e.preventDefault()
        loading.setIsLoading(true)

        try {
            await http.post('/role-access', {role_id, menus: checkedAccess})
            loading.setIsLoading(false)
            await Swal.fire({
                icon: "success",
                title: "Success to set Access Role!",
            })
        } catch (error) {
            loading.setIsLoading(false)
            Swal.fire({icon: "error", title: "Something went wrong"})
        }

        if(Number(cookieCutter.get('role_id')) === role_id){
            window.location.reload()
        }else{
            setModalManageOpen(false)
        }
    }

    const setDefaultAccessRole = async (roleAccess) => {
        // check child menu
        Array.from(roleAccess).forEach((access) => {
            const childMenu = document.querySelector(`#${access.parent_menu}_${access.child_menu}`)
            if(childMenu) childMenu.parentElement.classList.add('Mui-checked')
        })

        // check parent menu
        const roleAccessParent = [...new Set(roleAccess.map(access => access.parent_menu))]
        roleAccessParent.forEach((menu) => document.querySelector(`#${menu}_parent`).parentElement.classList.add('Mui-checked'))

        checkedAccess = roleAccess.map(({parent_menu, child_menu}) => ({parent_menu, child_menu}))
    }

    const handleCheckAccessParent = (e) => {
        const menus = document.querySelectorAll(`.${e.target.id.split('_')[0]}-child`)
        let isCheckedAccess

        if(menus.length > 0){
            Array.from(menus).forEach((menu) => {
                const child = menu.querySelector('input').id.split('_')
                isCheckedAccess = checkedAccess.findIndex((access) => access.parent_menu === child[0] && access.child_menu === child[1])
        
                if(!e.target.parentElement.classList.contains('Mui-checked')){
                    if(isCheckedAccess === -1) checkedAccess.push({parent_menu: child[0], child_menu: child[1]})
                    menu.classList.add("Mui-checked")
                }else{
                    if(isCheckedAccess !== -1) checkedAccess.splice(isCheckedAccess, 1)
                    menu.classList.remove("Mui-checked")
                }    
            })
        }else{
            isCheckedAccess = checkedAccess.findIndex((access) => access.parent_menu === e.target.id.split('_')[0])
            if(!e.target.parentElement.classList.contains('Mui-checked')){
                if(isCheckedAccess === -1) checkedAccess.push({parent_menu: e.target.id.split('_')[0], child_menu: null})
            }else{
                if(isCheckedAccess !== -1) checkedAccess.splice(isCheckedAccess, 1)
            }
        }
    }

    const handleCheckAccess = (e) => {
        const menu = e.target.id.split('_')
        const isCheckedAccess = checkedAccess.findIndex((access) => access.parent_menu === menu[0] && access.child_menu === menu[1])

        if(!e.target.parentElement.classList.contains('Mui-checked')){
            if(isCheckedAccess === -1) checkedAccess.push({parent_menu: menu[0], child_menu: menu[1]})
        }else{
            if(isCheckedAccess !== -1) checkedAccess.splice(isCheckedAccess, 1)
        }
        
        if(checkedAccess.filter((access) => access.parent_menu === menu[0]).length > 0){
            document.querySelector(`#${menu[0]}_parent`).parentElement.classList.add("Mui-checked")
        }else{
            document.querySelector(`#${menu[0]}_parent`).parentElement.classList.remove("Mui-checked")
        }
    }

    useEffect(() => {
        if(roleAccess.length > 0){
            setDefaultAccessRole(roleAccess)
        }
    }, [roleAccess])

    return <>
        <Modal
            open={modalManageOpen}
            onClose={() => setModalManageOpen(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <SoftBox sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                background: '#fff',
                boxShadow: 24,
                p: 4,
                minWidth: 800,
                borderRadius: '10px'
            }}>
                <SoftTypography id="modal-modal-title" variant="h5" component="h2">
                    Manage Role {role_name}
                </SoftTypography>
                <SoftBox sx={{ mt: 2 }}>
                <form onSubmit={(e) => setAccessRole(e)}>
                    <SoftBox  
                        display="grid"
                        gridTemplateColumns="repeat(3, 1fr)"
                        gridAutoRows="auto"
                        gap={4}
                        p={2}
                    >
                        {menus.map((menu, idx) => (
                            <RoleAccessComponent
                                menu_key={menu.key}
                                noCollapse={menu.noCollapse}
                                collapse={menu.collapse}
                                name={menu.name}
                                handleCheckAccessParent={handleCheckAccessParent}
                                handleCheckAccess={handleCheckAccess}
                            />
                        ))}
                    </SoftBox>
                    <SoftBox mb={3}>
                    <SoftButton 
                        color="info" 
                        size="small" 
                        type="submit" 
                        sx={{ mr: 1 }}
                        disabled={loading.isLoading}
                    >
                        {loading.isLoading ? <BeatLoader color="#21D4FD" /> : 'Submit'}
                    </SoftButton>
                    <SoftButton 
                        color="secondary" 
                        size="small" 
                        onClick={() => setModalManageOpen(false)}
                        disabled={loading.isLoading}
                    >
                        Cancel
                    </SoftButton>
                    </SoftBox>
                </form>
                </SoftBox>
            </SoftBox>
        </Modal>
    </>
}

const RoleManagement = () => {
    const [roles, setRoles] = useState([])
    const [modalManageOpen, setModalManageOpen] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [roleID, setRoleID] = useState(0)
    const [roleName, setRoleName] = useState(0)
    const [roleAccess, setRoleAccess] = useState([])
    const [page, setPage] = useState({
        onPage: 1,
        totalData: 0,
        totalPage: 0,
    })

    const getRoles = async () => {
        setIsLoading(true)
        try {
            const response = await http.get(`/role?page=${page.onPage}`);
            const totalPage = Math.ceil(response.data.data.total / 10);

            setRoles(response.data.data.results);
            setPage((curr) => ({
                onPage: totalPage < curr.onPage ? 1 : curr.onPage,
                totalData: response.data.data.total,
                totalPage: totalPage,
            }));
        } catch (error) {
            console.log(error);
        }
        setIsLoading(false)
    }

    const getAccessRole = async (role_id) => {
        const response = await http.get(`/role-access/${role_id}`)
        return response.data.data
    }

    const showModalManage = async (role_id, role_name) => {
        setModalManageOpen(true)
        setRoleID(role_id)
        setRoleName(role_name)
        setRoleAccess(await getAccessRole(role_id))
    }

    useEffect(() => {
        getRoles()
    }, [])

    return (
        <DashboardLayout>
            <DashboardNavbar />
            <ModalManageV2
                modalManageOpen={modalManageOpen}
                setModalManageOpen={setModalManageOpen}
                menus={routes.filter((route) => route.menu)}
                roleAccess={roleAccess}
                role_id={roleID}
                role_name={roleName}
                loading={{
                    isLoading,
                    setIsLoading
                }}
            />
            <SoftBox mb={2}>
                <TablePagination
                    columns={[
                        { name: "Name", align: "left" },
                        { name: "Description", align: "left" },
                        { name: "Action", align: "left" },
                    ]}
                    rows={roles.map((role) => ({
                        Name: role.name,
                        Description: role.description,
                        Action: <SoftButton size="small" color="info" onClick={() => showModalManage(role.id, role.name)}>Manage</SoftButton>,
                    }))}
                    page={page}
                    handleNextPage={() => {}}
                    handlePrevPage={() => {}}
                />
            </SoftBox>
        </DashboardLayout>
    )
}

export default RoleManagement